import { LatLngExpression } from 'leaflet';
import { AddActivityOption } from '../types/activity.types';
import { RoutesType } from '../types/general.types';
import {
  ColumnTemplate,
  AutonomousLockerCapabilities,
  LockerTemplate,
} from '../types/locker.types';

export const SIDEBAR_WIDTH = 180;
export const COLUMN_DRAWER_HEIGHT = 634;
export const MIN_WIDTH_ACTIONS = 130;
export const ITEMS_PER_PAGE = [10, 20, 50];
export const DEFAULT_ITEMS_PER_PAGE = 10;
export const PAGINATION_INITIAL_PAGE_INDEX = 0;

export const LOCKER_INCIDENT_KEYS = ['name', 'lockerCode', 'lockerState'];
export const LOCKER_INCIDENT_HEADERS = ['Nume', 'Cod', 'Stare'];

export const APP_VERSIONS_HEADERS = ['version', 'created_at'];
export const APP_VERSIONS_KEYS = ['version', 'createdAt'];

export const ROUTES: RoutesType = {
  dashboard: '/dashboard',
  customers: '/customers',
  users: '/users',
  lockers: '/lockers',
  clusters: '/clusters',
  activities: '/activities',
  events: '/events',
  configuration: '/configuration',
  packages_history: '/packages-history',
  reports: '/reports',
  account_settings: '/account-settings',
};

export const LOCKER_KEYS = [
  'lockerCode',
  'lockerState',
  'posActive',
  'createdAt',
  'updatedAt',
  'lastSyncDate',
  'mac',
  'appVersion',
  'name',
  'onlineStatus',
  'lockerType',
];

export const LOCKER_AVAILABLE_HEADERS = [
  'locker_code',
  'status',
  'pos_status',
  'created_at',
  'updated_at',
  'synced_at',
  'mac_address',
  'app_version',
  'name',
  'conectivity',
  'locker_type',
];

export const LOCKER_INCIDENTS_KEYS = [
  'lockerCode',
  'lockerState',
  'posActive',
  'onlineStatus',
  'lockerType',
  'mac',
  'appVersion',
  'name',
  'lastSyncDate',
  'updatedAt',
  'createdAt',
];

export const LOCKER_INCIDENTS_AVAILABLE_HEADERS = [
  'locker_code',
  'status',
  'pos_status',
  'conectivity',
  'locker_type',
  'mac_address',
  'app_version',
  'name',
  'synced_at',
  'updated_at',
  'created_at',
];

export const FLEET_LOAD_REPORT_KEYS = [
  'lockerCode',
  'loadingPercentage',
  'lockerType',
  'lockerLocationType',
];

export const FLEET_LOAD_REPORT_HEADERS = [
  'locker_code',
  'load_upper',
  'locker_type',
  'location',
];

export const LOCKER_LOAD_REPORT_HEADERS = [
  'row',
  'column',
  'drawer_type',
  'number_of_loads',
];

export const LOCKER_LOAD_REPORT_KEYS = [
  'row',
  'column',
  'drawerSize',
  'packageCount',
];

export const FLEET_UPTIME_REPORT_KEYS = [
  'lockerCode',
  'onlinePercentage',
  'lockerType',
  'lockerLocationType',
];

export const FLEET_UPTIME_REPORT_HEADERS = [
  'locker_code',
  'uptime_title',
  'locker_type',
  'location',
];

export const PACKAGE_TIME_ON_LOCKER_KEYS = [
  'lockerCode',
  'courierDropOffClientPickUp',
  'clientDropOffCourierPickUp',
  'lockerType',
  'lockerLocationType',
];

export const PACKAGE_TIME_ON_LOCKER_HEADERS = [
  'locker_code',
  'courier_customer_flow',
  'customer_courier_flow',
  'locker_type',
  'location',
];

export const DRAWERS_RENTAL_KEYS = [
  'customerName',
  'customerType',
  'ownedDrawers',
  'sharedDrawers',
];

export const DRAWERS_RENTAL_HEADERS = [
  'customer_name',
  'customer_type',
  'rented_drawers',
  'shared_drawers',
];

export const CUSTOMER_LOCKER_KEYS = ['lockerCode', 'name', 'lockerState'];
export const CUSTOMER_LOCKER_HEADERS = ['locker_code', 'name', 'status'];

export const CUSTOMER_ASSIGNED_LOCKER_HEADERS = [
  'locker_code',
  'owned_drawers',
  'total_number_of_drawers',
  'name',
  'status',
];
export const CUSTOMER_ASSIGNED_LOCKER_KEYS = [
  'lockerCode',
  'ownedDrawers',
  'totalDrawers',
  'name',
  'lockerState',
];
export const CUSTOMER_UNASSIGNED_LOCKER_HEADERS = [
  'locker_code',
  'available_drawers',
  'total_number_of_drawers',
  'name',
  'status',
];
export const CUSTOMER_UNASSIGNED_LOCKER_KEYS = [
  'lockerCode',
  'availabledDrawers',
  'totalDrawers',
  'name',
  'lockerState',
];
export const DRAWER_INCIDENT_KEYS = [
  'drawerState',
  'column',
  'row',
  'lockerCode',
  'drawerId',
  'createdAt',
  'updatedAt',
];

export const DRAWER_INCIDENT_HEADERS = [
  'status',
  'column',
  'row',
  'locker_code',
  'drawer_id',
  'created_at',
  'updated_at',
];

export const PACKAGE_INCIDENT_KEYS = [
  'packageId',
  'packageState',
  'createdAt',
  'updatedAt',
  'expirationDate',
  'workflow',
  'awb',
  'lockerId',
  'drawerId',
];

export const PACKAGE_INCIDENT_HEADERS = [
  'package_id',
  'status',
  'created_at',
  'updated_at',
  'expiration_date',
  'workflow',
  'awb',
  'locker_id',
  'drawer_id',
];

export const LOCKER_STATES: { [key: string]: string } = {
  1: 'active',
  2: 'inactive',
  3: 'sync_issue',
  4: 'cleaning_in_progress',
  5: 'maintenance_in_progress',
};

export const POS_STATES: { [key: string]: string } = {
  true: 'active',
  false: 'inactive',
};

export const LOCKER_LOCATION_TYPE: { [key: string]: string } = {
  1: 'inside',
  2: 'outside',
};

export const MIN_LENGTH_TEXT_FIELD = 3;
export const DATE_FORMAT = 'dd/MM/yyyy';
export const MIN_COMPANY_NAME_LENGTH = 3;
export const MAX_COMPANY_NAME_LENGTH = 70;
export const MIN_CUI_LENGTH = 2;
export const MAX_CUI_LENGTH = 12;
export const MIN_STREET_LENGTH = 3;
export const MAX_STREET_LENGTH = 100;
export const MAX_WEBSITE_LENGTH = 100;
export const MAX_EMAIL_LENGTH = 255;
export const MIN_PHONE_LENGTH = 3;
export const MAX_PHONE_LENGTH = 20;
export const MIN_FIRST_LAST_NAME_LENGTH = 3;
export const MAX_FIRST_LAST_NAME_LENGTH = 30;
export const MIN_FULL_NAME_LENGTH = 3;
export const MAX_FULL_NAME_LENGTH = 70;
export const MIN_BUILDING_NUMBER_LENGTH = 1;
export const MAX_BUILDING_NUMBER_LENGTH = 10;
export const MIN_FLOOR_LENGTH = 1;
export const MAX_FLOOR_LENGTH = 10;
export const RO_POSTAL_CODE_LENGTH = 6;
export const MAX_FREE_TEXT_LENGTH = 250;
export const MIN_LOCKER_CODE_LENGTH = 3;
export const MAX_LOCKER_CODE_LENGTH = 100;
export const MIN_BOARD_IDENTIFIER_LENGTH = 1;
export const MAX_BOARD_IDENTIFIER_LENGTH = 10;
export const MIN_CLUSTER_NAME_LENGTH = 3;
export const MAX_CLUSTER_NAME_LENGTH = 100;

export const EVENTS_KEYS = ['eventId', 'eventType', 'lockerCode', 'createdAt'];

export const EVENTS_AVAILABLE_HEADERS = [
  'event_id',
  'type',
  'locker_code',
  'created_at',
];

export const AUTONOMOUS_LOCKER_DEFAULT_BOARD_ID: string = '1';

export const VIDEO_KEYS = [
  'workflow',
  'momentType',
  'showStartDate',
  'showEndDate',
  'lockerCode',
];

export const VIDEO_HEADERS = [
  'workflow',
  'video_sequence_type',
  'start_at',
  'ends_at',
  'locker_code',
];

export const ACTIVITIES_KEYS = [
  'validFrom',
  'validTo',
  'authorizationType',
  'state',
  'user',
  'lockerCode',
  'allowSmartKeyOperation',
  'createdAt',
  'updatedAt',
];

export const ACTIVITIES_AVAILABLE_HEADERS = [
  'start_at',
  'ends_at',
  'type',
  'status',
  'user',
  'locker_code',
  'allows_smart_key_operations',
  'created_at',
  'updated_at',
];

export const ACTIVITIES_HISTORY_KEYS = [
  'authorizationType',
  'lockerCode',
  'user',
  'validFrom',
  'validTo',
  'isRevoked',
  'createdAt',
  'updatedAt',
];

export const LOCKER_DRAWERS_STATE_HEADERS = [
  'drawer_state',
  'row',
  'column',
  'column_identifier',
  'door_status',
  'drawer_type',
  'updated_at',
  'created_at',
];

export const LOCKER_DRAWERS_STATE_KEYS = [
  'drawerState',
  'row',
  'column',
  'board',
  'isDoorOpen',
  'drawerType',
  'updatedAt',
  'createdAt',
];

export const ACTION_LOGS_AVAILABLE_HEADERS = [
  'action_type',
  'locker_status',
  'drawer_state',
  'door_status',
  'creation_date',
  'drawer_id',
];

export const ACTION_LOGS_KEYS = [
  'actionLogType',
  'lockerState',
  'drawerState',
  'drawerIsDoorOpen',
  'createdAt',
  'drawerId',
];

export const LOCKER_LOGS_HEADERS = ['name', 'created_at'];

export const LOCKER_LOGS_KEYS = ['logName', 'logDate'];

export const USER_AVAILABLE_HEADERS = ['first_name', 'name', 'email', 'role'];

export const USER_KEYS = ['name', 'surname', 'emailAddress', 'userType'];

export const LokersOptions: AddActivityOption[] = [
  {
    id: 1,
    value: true,
    label: 'one_locker',
  },
  {
    id: 2,
    value: false,
    label: 'all_lockers',
  },
];

export const PACKAGE_AVAILABLE_HEADERS = [
  'locker_code',
  'package_state',
  'awb',
  'shipping_price',
  'package_price',
  'awb_type',
  'workflow',
  'created_at',
  'updated_at',
  'expiration_date',
  'customer_pin',
  'customer_name',
  'courier_code',
  'drawer_position',
  'drawer_id',
];

export const PACKAGE_KEYS = [
  'lockerCode',
  'packageState',
  'awb',
  'transportationAmount',
  'packageAmount',
  'awbType',
  'workflow',
  'createdAt',
  'updatedAt',
  'expirationDate',
  'clientPIN',
  'customerName',
  'courierCode',
  'drawerPosition',
  'drawerId',
];

export const PACKAGE_SNAPSHOTS_AVAILABLE_HEADERS = [
  'awb_type',
  'package_id',
  'package_state',
  'shipping_price',
  'package_price',
  'drawer_type',
  'workflow',
  'locker_id',
  'locker_status',
  'expiration_date',
  'drawer_id',
  'drawer_state',
  'door_status',
  'courier_code',
  'customer_pin',
  'created_at',
  'updated_at',
];

export const PACKAGE_SNAPSHOTS_KEYS = [
  'awbType',
  'packageId',
  'packageState',
  'transportationAmount',
  'packageAmount',
  'assignedSize',
  'workflow',
  'lockerId',
  'lockerState',
  'expirationDate',
  'drawerId',
  'drawerState',
  'drawerIsDoorOpen',
  'courierCode',
  'clientPIN',
  'snapshotCreationDate',
  'packageUpdatedAt',
];

export const CUSTOMERS_AVAILABLE_HEADERS = [
  'name',
  'fiscal_code',
  'customer_type',
  'created_at',
  'updated_at',
];

export const CUSTOMERS_KEYS = [
  'companyName',
  'companyCUI',
  'customerType',
  'createdAt',
  'updatedAt',
];

export const ASSIGN_DRAWERS_DIALOG_TYPES = {
  LOCKER_UNASSIGNEMENT: 'LOCKER_UNASSIGNEMENT',
  COLUMN_UNASSIGNEMENT: 'COLUMN_UNASSIGNEMENT',
  DRAWER_UNASSIGNEMENT: 'DRAWER_UNASSIGNEMENT',
  DRAWERS_ASSIGNEMENT: 'DRAWERS_ASSIGNEMENT',
};

export const errorMessages: Record<string, string> = {
  APPVERSION_ALREADY_EXISTS: 'APPVERSION_ALREADY_EXISTS',
  MISSING_APPVERSION: 'MISSING_APPVERSION',
  TARGET_VERSION_NOT_FOUND: 'TARGET_VERSION_NOT_FOUND',
  TARGET_VERSION_TO_SMALL: 'TARGET_VERSION_TO_SMALL',
  INVALID_FILE_NAME_FORMAT: 'INVALID_FILE_NAME_FORMAT',
  INVALID_REFERENCE_VERSION: 'INVALID_REFERENCE_VERSION',
  ADDRESS_LOCALITY_NOT_FOUND: 'ADDRESS_LOCALITY_NOT_FOUND',
  ADDRESS_COUNTY_NOT_FOUND: 'ADDRESS_COUNTY_NOT_FOUND',
  ADDRESS_COUNTY_MANDATORY: 'ADDRESS_COUNTY_MANDATORY',
  USER_UNAUTHORIZED: 'USER_UNAUTHORIZED',
  INVALID_LOCKER_DELETION_STATE: 'INVALID_LOCKER_DELETION_STATE',
  INVALID_LOCKER_STATE: 'INVALID_LOCKER_STATE',
  ALREADY_EXISTING_LOCKER: 'ALREADY_EXISTING_LOCKER',
  LOCKER_NOT_FOUND: 'LOCKER_NOT_FOUND',
  INTERNAL_SERVER_ERROR: 'INTERNAL_SERVER_ERROR',
  PACKAGE_BY_ID_NOT_FOUND: 'PACKAGE_BY_ID_NOT_FOUND',
  PACKAGE_BY_AWB_NOT_FOUND: 'PACKAGE_BY_AWB_NOT_FOUND',
  CAN_NOT_UPDATE_OLD_AUTHORIZATION: 'CAN_NOT_UPDATE_OLD_AUTHORIZATION',
  INVALID_DATETIME_OF_VALIDTO: 'INVALID_DATETIME_OF_VALIDTO',
  AUTHORIZATION_IS_ALREADY_REVOKED: 'AUTHORIZATION_IS_ALREADY_REVOKED',
  CAN_NOT_SET_DRAWER_STATUS_INUSE: 'CAN_NOT_SET_DRAWER_STATUS_INUSE',
  INVALID_DRAWER_STATUS_INUSE: 'INVALID_DRAWER_STATUS_INUSE',
  INVALID_CREATE_NEW_DELETED_DRAWER: 'INVALID_CREATE_NEW_DELETED_DRAWER',
  DRAWER_POSITION_NOT_UNIQUE: 'DRAWER_POSITION_NOT_UNIQUE',
  DRAWER_CAN_NOT_BE_DELETED: 'DRAWER_CAN_NOT_BE_DELETED',
  DRAWER_NOT_FOUND_ON_LOCKER: 'DRAWER_NOT_FOUND_ON_LOCKER',
  DRAWER_NOT_FOUND: 'DRAWER_NOT_FOUND',
  AUTHORIZATION_NOT_FOUND: 'AUTHORIZATION_NOT_FOUND',
  INVALID_USER_EMAIL: 'INVALID_USER_EMAIL',
  USER_ALREADY_DELETED: 'USER_ALREADY_DELETED',
  USER_NOT_FOUND: 'USER_NOT_FOUND',
  ALREADY_EXISTING_CUSTOMER: 'ALREADY_EXISTING_CUSTOMER',
  COMPANY_NAME_ALREADY_EXIST: 'COMPANY_NAME_ALREADY_EXIST',
  COMPANY_CUI_ALREADY_EXIST: 'COMPANY_CUI_ALREADY_EXIST',
  LOCKER_IS_PART_OF_ANOTHER_CLUSTER: 'LOCKER_IS_PART_OF_ANOTHER_CLUSTER',
  INVALID_CUSTOMER_TYPE: 'INVALID_CUSTOMER_TYPE',
  LOCKER_IS_NOT_IN_GOOD_POSITION: 'LOCKER_IS_NOT_IN_GOOD_POSITION',
  COMMAND_OF_THIS_TYPE_ALLREADY_PENDING:
    'COMMAND_OF_THIS_TYPE_ALLREADY_PENDING',
  NOT_ENOUGH_DRAWERS: 'NOT_ENOUGH_DRAWERS',
  CANNOT_SET_DRAWER_ACTIVE_PACKAGE_IS_ASSIGNED_TO_DRAWER:
    'CANNOT_SET_DRAWER_ACTIVE_PACKAGE_IS_ASSIGNED_TO_DRAWER',
  INVALID_SMART_KEY_OPERATION: 'INVALID_SMART_KEY_OPERATION',
};

export const customerListDataGridLegend = [
  { label: 'active_customer', bgColorClass: 'client-active' },
  { label: 'inactive_customer', bgColorClass: 'client-inactive' },
];
export const lockerListDataGridLegend = [
  { label: 'fully_configured_locker', bgColorClass: 'locker-complete' },
  {
    label: 'incompletely_configured_locker',
    bgColorClass: 'locker-incomplete',
  },
  { label: 'deleted_locker', bgColorClass: 'locker-deleted' },
  { label: 'connected_locker', bgColorClass: 'locker-type-connected' },
  { label: 'autonomous_locker', bgColorClass: 'locker-type-autonomous' },
  { label: 'locker_included_in_cluster', bgColorClass: 'cluster-locker' },
];
export const assignedDrawersDataGridLegend = [
  { label: 'assigned_drawer', bgColorClass: 'assigned-drawer' },
  {
    label: 'drawer_selected_for_assignment',
    bgColorClass: 'drawer-selected',
  },
  { label: 'unassigned_drawer', bgColorClass: 'unassigned-drawer' },
  { label: 'active_drawer', bgColorClass: 'active-drawer' },
  { label: 'inactive_drawer', bgColorClass: 'inactive-drawer' },
  { label: 'drawer_in_use', bgColorClass: 'in-use-drawer' },
  {
    label: 'drawer_requires_maintenance',
    bgColorClass: 'drawer-requires-maintenance',
  },
  {
    label: 'drawer_requires_cleaning',
    bgColorClass: 'drawer-requires-cleaning',
  },
  {
    label: 'drawer_requires_maintenance_in_use',
    bgColorClass: 'drawer-requires-maintenance-in-use',
  },
  {
    label: 'drawer_cleaning_in_progress',
    bgColorClass: 'drawer-cleaning-in-progress',
  },
  {
    label: 'drawer_maintenance_in_progress',
    bgColorClass: 'drawer-maintenance-in-progress',
  },
];

export const DRAWERS_ID_PREFIX = 'generated';
export const COLUMN_ID_PREFIX = 'generated-column';

export const LOCKER_TYPES = {
  CONNECTED_LOCKER: 1,
  AUTHONOMOUS_LOCKER: 2,
};

export const CONNECTED_LOCKER = 'conectat';
export const ROW_DISABLED_CLASS = 'row-disabled';

export const CLUSTER_LIST_KEYS = ['name', 'createdAt', 'updatedAt'];
export const CLUSTER_LIST_HEADERS = ['name', 'created_at', 'updated_at'];

export const CLUSTERS_LOAD_REPORT_KEYS = ['clusterName', 'clusterPercentage'];
export const CLUSTERS_LOAD_REPORT_HEADERS = ['name', 'load_upper'];

export const MINIMUM_LOCKERS_IN_CLUSTER = 2;
export const MAXIMUM_LOCKERS_IN_CLUSTER = 10;

export const CONNECTED_LOCKER_TABS = [
  { label: 'general_information', component: 'EditLocker', path: './' },
  {
    label: 'hardware_capabilities',
    component: 'LockerCapabilities',
    path: './hardware-capabilities',
  },
  {
    label: 'assign_drawers',
    component: 'AssignDrawers',
    path: './assign-drawers',
  },
  {
    label: 'drawer_structure',
    component: 'EditLockerStructure',
    path: './drawer-structure',
  },
  { label: 'drawer_state', component: 'DrawersState', path: './drawer-state' },
  {
    label: 'packages_history',
    component: 'PackageHistory',
    path: './packages-history',
  },
  {
    label: 'activities_history',
    component: 'ActivitiesHistory',
    path: './activities-history',
  },
  {
    label: 'deposit_withdrawal_history',
    component: 'DropOffAndPickUpPackages',
    path: './desposit-withdrawal-history',
  },
];
export const AUTONOMOUS_LOCKER_TABS = [
  { label: 'general_information', component: 'EditLocker', path: './' },
  {
    label: 'hardware_capabilities',
    component: 'LockerCapabilities',
    path: './hardware-capabilities',
  },
  {
    label: 'connecting_information',
    component: 'ConnectionData',
    path: './connecting-information',
  },
  {
    label: 'assign_drawers',
    component: 'AssignDrawers',
    path: './assign-drawers',
  },
  {
    label: 'drawer_structure',
    component: 'EditLockerStructure',
    path: './drawer-structure',
  },
  { label: 'drawers_state', component: 'DrawersState', path: './drawer-state' },
  {
    label: 'packages_history',
    component: 'PackageHistory',
    path: './packages-history',
  },
  {
    label: 'activities_history',
    component: 'ActivitiesHistory',
    path: './activities-history',
  },
  {
    label: 'deposit_withdrawal_history',
    component: 'DropOffAndPickUpPackages',
    path: './desposit-withdrawal-history',
  },
];

export const ABOVE_AVERAGE_FILL_COLOR = '#4caf50';
export const AVERAGE_FILL_COLOR = '#ed6c02';
export const BELLOW_AVERAGE_FILL_COLOR = '#d32f2f';

export const ABOVE_AVERAGE_THRESHOLD = 90;
export const AVERAGE_THRESHOLD = 50;
export const BELLOW_AVERAGE_THRESHOLD = 50;
export const START_YEAR = '2023';

export const ASSIGN_DRAWERS_ON_FLEET_KEYS = [
  'lockerCode',
  'lockerType',
  'lockerLocationType',
];

export const ASSIGN_DRAWERS_ON_FLEET_HEADERS = [
  'locker_code',
  'locker_type',
  'location',
];

export const INITAL_MAP_ZOOM_LEVEL = 6;
export const MAP_MAX_ZOOM_LEVEL = 18;
export const INITAL_CENTER_OF_THE_MAP_COORDINATES: LatLngExpression = [46, 25]; // center of Romania
export const MAP_CLUSTER_RADIUS = 150;
export const ROMANIA_COORDINATES_BOUNDS = [
  43.618682, 48.2654738, 20.2619955, 30.0454257,
];

export const RESERVED_COLUMNS_IDENTIFIERS = ['C1', 'C2', 'C3'];
export const MANAGEMENT_LOCALE_STOARGE_KEY = 'MANAGEMENT_LOCALE';
export const MANAGEMENT_ACCESS_TOKEN_KEY = 'MANAGEMENT_ACCESS_TOKEN';
export const MANAGEMENT_TIMEZONE_STOARGE_KEY =
  'MANAGEMENT_TIMEZONE_STOARGE_KEY';
export const MANAGEMENT_COUNTRY_CODE_STOARGE_KEY =
  'MANAGEMENT_COUNTRY_CODE_STOARGE_KEY';
export const SET_PASSWORD_ROUTE = 'create-password';
export const RESET_PASSWORD_ROUTE = 'reset-password';
export const CARRIER_PASSWORD_ROUTE = 'create-password';
export const PARTNER_PASSWORD_ROUTE = 'create-password';

export const MAP_LOCALE_TO_DATE_FNS_LOCALE: Record<string, string> = {
  'ro-RO': 'ro',
  'en-US': 'en-US',
};

export const MAP_DRAWER_STATE_TO_CLASS: Record<number, string> = {
  1: 'active',
  2: 'inactive',
  3: 'in-use',
  4: 'maintenance-required',
  5: 'cleaning-required',
  6: 'maintenance-required-in-use',
  7: 'cleaning-in-progress',
  8: 'maintenance-in-progress',
};

export const LOCKER_COMMANDS_LOG_HEADERS = [
  'command_type',
  'drawer_position',
  'status',
  'expires_at',
  'created_at',
];

export const LOCKER_COMMANDS_LOG_KEYS = [
  'commandType',
  'drawerPosition',
  'executionStatus',
  'commandExpirationDate',
  'createdAt',
];

export const TIMEZONES: Record<string, string> = {
  'Africa/Abidjan': '(UTC+00:00) Africa/Abidjan',
  'Africa/Algiers': '(UTC+01:00) Africa/Algiers',
  'Africa/Bissau': '(UTC+00:00) Africa/Bissau',
  'Africa/Cairo': '(UTC+02:00, +03:00) Africa/Cairo',
  'Africa/Casablanca': '(UTC+00:00, +01:00) Africa/Casablanca',
  'Africa/Ceuta': '(UTC+01:00, +02:00) Africa/Ceuta',
  'Africa/El_Aaiun': '(UTC+00:00, +01:00) Africa/El Aaiun',
  'Africa/Johannesburg': '(UTC+02:00) Africa/Johannesburg',
  'Africa/Juba': '(UTC+02:00, +03:00) Africa/Juba',
  'Africa/Khartoum': '(UTC+02:00, +03:00) Africa/Khartoum',
  'Africa/Lagos': '(UTC+01:00) Africa/Lagos',
  'Africa/Maputo': '(UTC+02:00) Africa/Maputo',
  'Africa/Monrovia': '(UTC+00:00) Africa/Monrovia',
  'Africa/Nairobi': '(UTC+03:00) Africa/Nairobi',
  'Africa/Ndjamena': '(UTC+01:00) Africa/Ndjamena',
  'Africa/Sao_Tome': '(UTC+00:00, +01:00) Africa/Sao Tome',
  'Africa/Tripoli': '(UTC+01:00, +02:00) Africa/Tripoli',
  'Africa/Tunis': '(UTC+01:00, +02:00) Africa/Tunis',
  'Africa/Windhoek': '(UTC+01:00, +02:00) Africa/Windhoek',
  'America/Adak': '(UTC-10:00, -09:00) America/Adak',
  'America/Anchorage': '(UTC-09:00, -08:00) America/Anchorage',
  'America/Araguaina': '(UTC-03:00, -02:00) America/Araguaina',
  'America/Argentina/Buenos_Aires':
    '(UTC-03:00, -02:00) America/Argentina/Buenos Aires',
  'America/Argentina/Catamarca':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Catamarca',
  'America/Argentina/Cordoba': '(UTC-03:00, -02:00) America/Argentina/Cordoba',
  'America/Argentina/Jujuy': '(UTC-03:00, -02:00) America/Argentina/Jujuy',
  'America/Argentina/La_Rioja':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/La Rioja',
  'America/Argentina/Mendoza':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Mendoza',
  'America/Argentina/Rio_Gallegos':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Rio Gallegos',
  'America/Argentina/Salta': '(UTC-03:00, -02:00) America/Argentina/Salta',
  'America/Argentina/San_Juan':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/San Juan',
  'America/Argentina/San_Luis':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/San Luis',
  'America/Argentina/Tucuman':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Tucuman',
  'America/Argentina/Ushuaia':
    '(UTC-04:00, -03:00, -02:00) America/Argentina/Ushuaia',
  'America/Asuncion': '(UTC-04:00, -03:00) America/Asuncion',
  'America/Bahia': '(UTC-03:00, -02:00) America/Bahia',
  'America/Bahia Banderas':
    '(UTC-07:00, -06:00, -05:00) America/Bahia Banderas',
  'America/Barbados': '(UTC-04:00) America/Barbados',
  'America/Belem': '(UTC-03:00) America/Belem',
  'America/Belize': '(UTC-06:00) America/Belize',
  'America/Boa_Vista': '(UTC-04:00, -03:00) America/Boa Vista',
  'America/Bogota': '(UTC-05:00) America/Bogota',
  'America/Boise': '(UTC-07:00, -06:00) America/Boise',
  'America/Cambridge_Bay': '(UTC-07:00, -06:00, -05:00) America/Cambridge Bay',
  'America/Campo_Grande': '(UTC-04:00, -03:00) America/Campo Grande',
  'America/Cancun': '(UTC-06:00, -05:00) America/Cancun',
  'America/Caracas': '(UTC-04:30, -04:00) America/Caracas',
  'America/Cayenne': '(UTC-03:00) America/Cayenne',
  'America/Chicago': '(UTC-06:00, -05:00) America/Chicago',
  'America/Chihuahua': '(UTC-07:00, -06:00) America/Chihuahua',
  'America/Ciudad_Juarez': '(UTC-07:00, -06:00) America/Ciudad Juarez',
  'America/Costa_Rica': '(UTC-06:00) America/Costa Rica',
  'America/Cuiaba': '(UTC-04:00, -03:00) America/Cuiaba',
  'America/Danmarkshavn': '(UTC+00:00) America/Danmarkshavn',
  'America/Dawson': '(UTC-08:00, -07:00) America/Dawson',
  'America/Dawson_Creek': '(UTC-07:00) America/Dawson Creek',
  'America/Denver': '(UTC-07:00, -06:00) America/Denver',
  'America/Detroit': '(UTC-05:00, -04:00) America/Detroit',
  'America/Edmonton': '(UTC-07:00, -06:00) America/Edmonton',
  'America/Eirunepe': '(UTC-05:00, -04:00) America/Eirunepe',
  'America/El_Salvador': '(UTC-06:00) America/El Salvador',
  'America/Fort_Nelson': '(UTC-08:00, -07:00) America/Fort Nelson',
  'America/Fortaleza': '(UTC-03:00, -02:00) America/Fortaleza',
  'America/Glace_Bay': '(UTC-04:00, -03:00) America/Glace Bay',
  'America/Goose_Bay': '(UTC-04:00, -03:00) America/Goose Bay',
  'America/Grand_Turk': '(UTC-05:00, -04:00) America/Grand Turk',
  'America/Guatemala': '(UTC-06:00, -05:00) America/Guatemala',
  'America/Guayaquil': '(UTC-05:00) America/Guayaquil',
  'America/Guyana': '(UTC-04:00) America/Guyana',
  'America/Halifax': '(UTC-04:00, -03:00) America/Halifax',
  'America/Havana': '(UTC-05:00, -04:00) America/Havana',
  'America/Hermosillo': '(UTC-07:00) America/Hermosillo',
  'America/Indiana/Indianapolis':
    '(UTC-05:00, -04:00) America/Indiana/Indianapolis',
  'America/Indiana/Knox': '(UTC-06:00, -05:00) America/Indiana/Knox',
  'America/Indiana/Marengo': '(UTC-05:00, -04:00) America/Indiana/Marengo',
  'America/Indiana/Petersburg':
    '(UTC-06:00, -05:00, -04:00) America/Indiana/Petersburg',
  'America/Indiana/Tell_City': '(UTC-06:00, -05:00) America/Indiana/Tell City',
  'America/Indiana/Vevay': '(UTC-05:00, -04:00) America/Indiana/Vevay',
  'America/Indiana/Vincennes':
    '(UTC-06:00, -05:00, -04:00) America/Indiana/Vincennes',
  'America/Indiana/Winamac':
    '(UTC-06:00, -05:00, -04:00) America/Indiana/Winamac',
  'America/Inuvik': '(UTC-07:00, -06:00) America/Inuvik',
  'America/Iqaluit': '(UTC-06:00, -05:00, -04:00) America/Iqaluit',
  'America/Jamaica': '(UTC-05:00) America/Jamaica',
  'America/Juneau': '(UTC-09:00, -08:00) America/Juneau',
  'America/Kentucky/Louisville':
    '(UTC-05:00, -04:00) America/Kentucky/Louisville',
  'America/Kentucky/Monticello':
    '(UTC-06:00, -05:00, -04:00) America/Kentucky/Monticello',
  'America/La_Paz': '(UTC-04:00) America/La Paz',
  'America/Lima': '(UTC-05:00) America/Lima',
  'America/Los_Angeles': '(UTC-08:00, -07:00) America/Los Angeles',
  'America/Maceio': '(UTC-03:00, -02:00) America/Maceio',
  'America/Managua': '(UTC-06:00, -05:00) America/Managua',
  'America/Manaus': '(UTC-04:00) America/Manaus',
  'America/Martinique': '(UTC-04:00) America/Martinique',
  'America/Matamoros': '(UTC-06:00, -05:00) America/Matamoros',
  'America/Mazatlan': '(UTC-07:00, -06:00) America/Mazatlan',
  'America/Menominee': '(UTC-06:00, -05:00) America/Menominee',
  'America/Merida': '(UTC-06:00, -05:00) America/Merida',
  'America/Metlakatla': '(UTC-09:00, -08:00) America/Metlakatla',
  'America/Mexico_City': '(UTC-06:00, -05:00) America/Mexico City',
  'America/Miquelon': '(UTC-03:00, -02:00) America/Miquelon',
  'America/Moncton': '(UTC-04:00, -03:00) America/Moncton',
  'America/Monterrey': '(UTC-06:00, -05:00) America/Monterrey',
  'America/Montevideo': '(UTC-03:00, -02:00) America/Montevideo',
  'America/New_York': '(UTC-05:00, -04:00) America/New York',
  'America/Nome': '(UTC-09:00, -08:00) America/Nome',
  'America/Noronha': '(UTC-02:00, -01:00) America/Noronha',
  'America/North_Dakota/Beulah':
    '(UTC-07:00, -06:00, -05:00) America/North_Dakota/Beulah',
  'America/North_Dakota/Center':
    '(UTC-06:00, -05:00) America/North_Dakota/Center',
  'America/North_Dakota/New_Salem':
    '(UTC-07:00, -06:00, -05:00) America/North_Dakota/New Salem',
  'America/Nuuk': '(UTC-03:00, -02:00, -01:00) America/Nuuk',
  'America/Ojinaga': '(UTC-07:00, -06:00, -05:00) America/Ojinaga',
  'America/Panama': '(UTC-05:00) America/Panama',
  'America/Paramaribo': '(UTC-03:00) America/Paramaribo',
  'America/Phoenix': '(UTC-07:00) America/Phoenix',
  'America/Port-au-Prince': '(UTC-05:00, -04:00) America/Port-au-Prince',
  'America/Porto_Velho': '(UTC-04:00) America/Porto Velho',
  'America/Puerto_Rico': '(UTC-04:00) America/Puerto Rico',
  'America/Punta_Arenas': '(UTC-04:00, -03:00) America/Punta Arenas',
  'America/Rankin_Inlet': '(UTC-06:00, -05:00) America/Rankin Inlet',
  'America/Recife': '(UTC-03:00, -02:00) America/Recife',
  'America/Regina': '(UTC-06:00) America/Regina',
  'America/Resolute': '(UTC-06:00, -05:00) America/Resolute',
  'America/Rio_Branco': '(UTC-05:00, -04:00) America/Rio Branco',
  'America/Santarem': '(UTC-04:00, -03:00) America/Santarem',
  'America/Santiago': '(UTC-04:00, -03:00) America/Santiago',
  'America/Santo_Domingo': '(UTC-05:00, -04:00) America/Santo Domingo',
  'America/Sao_Paulo': '(UTC-03:00, -02:00) America/Sao Paulo',
  'America/Scoresbysund': '(UTC-01:00, 00:00) America/Scoresbysund',
  'America/Sitka': '(UTC-09:00, -08:00) America/Sitka',
  'America/St_Johns': '(UTC-03:30, -02:30) America/St Johns',
  'America/Swift_Current': '(UTC-06:00) America/Swift Current',
  'America/Tegucigalpa': '(UTC-06:00, -05:00) America/Tegucigalpa',
  'America/Thule': '(UTC-04:00, -03:00) America/Thule',
  'America/Tijuana': '(UTC-08:00, -07:00) America/Tijuana',
  'America/Toronto': '(UTC-05:00, -04:00) America/Toronto',
  'America/Vancouver': '(UTC-08:00, -07:00) America/Vancouver',
  'America/Whitehorse': '(UTC-08:00, -07:00) America/Whitehorse',
  'America/Winnipeg': '(UTC-06:00, -05:00) America/Winnipeg',
  'America/Yakutat': '(UTC-09:00, -08:00) America/Yakutat',
  'Antarctica/Casey': '(UTC+08:00, +11:00) Antarctica/Casey',
  'Antarctica/Davis': '(UTC+05:00, +07:00) Antarctica/Davis',
  'Antarctica/Macquarie': '(UTC+10:00, +11:00) Antarctica/Macquarie',
  'Antarctica/Mawson': '(UTC+05:00, +06:00) Antarctica/Macquarie',
  'Antarctica/Palmer': '(UTC-04:00, -03:00) Antarctica/Palmer',
  'Antarctica/Rothera': '(UTC-03:00) Antarctica/Rothera',
  'Antarctica/Troll': '(UTC+00:00, +02:00) Antarctica/Troll',
  'Asia/Almaty': '(UTC+06:00, +07:00) Asia/Almaty',
  'Asia/Amman': '(UTC+02:00, +03:00) Asia/Amman',
  'Asia/Anadyr': '(UTC+11:00, +12:00, +13:00) Asia/Anadyr',
  'Asia/Aqtau': '(UTC+04:00, +05:00) Asia/Aqtau',
  'Asia/Aqtobe': '(UTC+05:00, +06:00) Asia/Aqtobe',
  'Asia/Ashgabat': '(UTC+05:00) Asia/Ashgabat',
  'Asia/Atyrau': '(UTC+04:00, +05:00) Asia/Atyrau',
  'Asia/Baghdad': '(UTC+03:00, +04:00) Asia/Baghdad',
  'Asia/Baku': '(UTC+04:00, +05:00) Asia/Baku',
  'Asia/Bangkok': '(UTC+07:00) Asia/Bangkok',
  'Asia/Barnaul': '(UTC+06:00, +07:00) Asia/Barnaul',
  'Asia/Beirut': '(UTC+02:00, +03:00) Asia/Beirut',
  'Asia/Bishkek': '(UTC+05:00, +06:00) Asia/Bishkek',
  'Asia/Chita': '(UTC+08:00, +09:00, +10:00) Asia/Chita',
  'Asia/Choibalsan': '(UTC+08:00, +09:00, +10:00) Asia/Choibalsan',
  'Asia/Colombo': '(UTC+05:30, +06:00) Asia/Colombo',
  'Asia/Damascus': '(UTC+02:00, +03:00) Asia/Damascus',
  'Asia/Dhaka': '(UTC+06:00, +07:00) Asia/Dhaka',
  'Asia/Dili': '(UTC+08:00, +09:00) Asia/Dili',
  'Asia/Dubai': '(UTC+04:00) Asia/Dubai',
  'Asia/Dushanbe': '(UTC+05:00) Asia/Dushanbe',
  'Asia/Famagusta': '(UTC+02:00, +03:00) Asia/Famagusta',
  'Asia/Gaza': '(UTC+02:00, +03:00) Asia/Gaza',
  'Asia/Hebron': '(UTC+02:00, +03:00) Asia/Hebron',
  'Asia/Ho_Chi_Minh': '(UTC+07:00) Asia/Ho Chi Minh',
  'Asia/Hong_Kong': '(UTC+08:00) Asia/Hong Kong',
  'Asia/Hovd': '(UTC+07:00, +08:00) Asia/Hovd',
  'Asia/Jakarta': '(UTC+07:00) Asia/Jakarta',
  'Asia/Jayapura': '(UTC+09:00) Asia/Jayapura',
  'Asia/Jerusalem': '(UTC+02:00, +03:00) Asia/Jerusalem',
  'Asia/Karachi': '(UTC+05:00, +06:00) Asia/Karachi',
  'Asia/Kathmandu': '(UTC+05:45) Asia/Kathmandu',
  'Asia/Kolkata': '(UTC+05:30) Asia/Kolkata',
  'Asia/Kuching': '(UTC+08:00) Asia/Kuching',
  'Asia/Macau': '(UTC+08:00) Asia/Macau',
  'Asia/Makassar': '(UTC+08:00) Asia/Makassar',
  'Asia/Manila': '(UTC+08:00) Asia/Manila',
  'Asia/Nicosia': '(UTC+02:00, +03:00) Asia/Nicosia',
  'Asia/Oral': '(UTC+04:00, +05:00) Asia/Oral',
  'Asia/Pontianak': '(UTC+07:00) Asia/Pontianak',
  'Asia/Qatar': '(UTC+03:00) Asia/Qatar',
  'Asia/Qostanay': '(UTC+05:00, +06:00) Asia/Qostanay',
  'Asia/Qyzylorda': '(UTC+05:00, +06:00) Asia/Qyzylorda',
  'Asia/Riyadh': '(UTC+03:00) Asia/Riyadh',
  'Asia/Samarkand': '(UTC+05:00) Asia/Samarkand',
  'Asia/Seoul': '(UTC+09:00) Asia/Seoul',
  'Asia/Shanghai': '(UTC+08:00) Asia/Shanghai',
  'Asia/Taipei': '(UTC+08:00) Asia/Taipei',
  'Asia/Tashkent': '(UTC+05:00) Asia/Tashkent',
  'Asia/Tbilisi': '(UTC+03:00, +04:00, +05:00) Asia/Tbilisi',
  'Asia/Thimphu': '(UTC+06:00) Asia/Thimphu',
  'Asia/Tokyo': '(UTC+09:00) Asia/Tokyo',
  'Asia/Ulaanbaatar': '(UTC+08:00, +09:00) Asia/Ulaanbaatar',
  'Asia/Urumqi': '(UTC+06:00) Asia/Urumqi',
  'Asia/Yangon': '(UTC+06:30) Asia/Yangon',
  'Asia/Yerevan': '(UTC+04:00, +05:00) Asia/Yerevan',
  'Atlantic/Azores': '(UTC-01:00, +00:00) Atlantic/Azores',
  'Atlantic/Bermuda': '(UTC-04:00, -03:00) Atlantic/Bermuda',
  'Atlantic/Canary': '(UTC+00:00, +01:00) Atlantic/Canary',
  'Atlantic/Cape_Verde': '(UTC-01:00) Atlantic/Cape Verde',
  'Atlantic/Faroe': '(UTC+00:00, +01:00) Atlantic/Faroe',
  'Atlantic/Madeira': '(UTC+00:00, +01:00) Atlantic/Madeira',
  'Atlantic/South_Georgia': '(UTC-02:00) Atlantic/South Georgia',
  'Atlantic/Stanley': '(UTC-04:00, -03:00) Atlantic/Stanley',
  'Australia/Adelaide': '(UTC+09:30, +10:30) Australia/Adelaide',
  'Australia/Brisbane': '(UTC+10:00) Australia/Brisbane',
  'Australia/Broken_Hill': '(UTC+09:30, +10:30) Australia/Broken Hill',
  'Australia/Darwin': '(UTC+09:30) Australia/Darwin',
  'Australia/Eucla': '(UTC+08:45, +09:45) Australia/Eucla',
  'Australia/Hobart': '(UTC+10:00, +11:00) Australia/Hobart',
  'Australia/Lindeman': '(UTC+10:00) Australia/Lindeman',
  'Australia/Lord_Howe': '(UTC+10:30, +11:00) Australia/Lord Howe',
  'Australia/Melbourne': '(UTC+10:00, +11:00) Australia/Melbourne',
  'Australia/Perth': '(UTC+08:00, +09:00) Australia/Perth',
  'Australia/Sydney': '(UTC+10:00, +11:00) Australia/Sydney',
  'Europe/Andorra': '(UTC+01:00, +02:00) Europe/Andorra',
  'Europe/Athens': '(UTC+02:00, +03:00) Europe/Athens',
  'Europe/Belgrade': '(UTC+01:00, +02:00) Europe/Belgrade',
  'Europe/Berlin': '(UTC+01:00, +02:00) Europe/Berlin',
  'Europe/Brussels': '(UTC+01:00, +02:00) Europe/Brussels',
  'Europe/Bratislava': '(UTC+01:00, +02:00) Europe/Bratislava',
  'Europe/Bucharest': '(UTC+02:00, +03:00) Europe/Bucharest',
  'Europe/Budapest': '(UTC+01:00, +02:00) Europe/Budapest',
  'Europe/Chisinau': '(UTC+02:00, +03:00) Europe/Chisinau',
  'Europe/Dublin': '(UTC+00:00, +01:00) Europe/Dublin',
  'Europe/Gibraltar': '(UTC+01:00, +02:00) Europe/Gibraltar',
  'Europe/Helsinki': '(UTC+02:00, +03:00) Europe/Helsinki',
  'Europe/Istanbul': '(UTC+02:00, +03:00) Europe/Istanbul',
  'Europe/Kyiv': '(UTC+02:00, +03:00) Europe/Kyiv',
  'Europe/Lisbon': '(UTC+00:00, +01:00) Europe/Lisbon',
  'Europe/London': '(UTC+00:00, +01:00) Europe/London',
  'Europe/Madrid': '(UTC+01:00, +02:00) Europe/Madrid',
  'Europe/Malta': '(UTC+01:00, +02:00) Europe/Malta',
  'Europe/Paris': '(UTC+01:00, +02:00) Europe/Paris',
  'Europe/Prague': '(UTC+01:00, +02:00) Europe/Prague',
  'Europe/Riga': '(UTC+02:00, +03:00) Europe/Riga',
  'Europe/Rome': '(UTC+01:00, +02:00) Europe/Rome',
  'Europe/Simferopol': '(UTC+02:00, +03:00, +04:00) Europe/Simferopol',
  'Europe/Sofia': '(UTC+02:00, +03:00) Europe/Sofia',
  'Europe/Tallinn': '(UTC+02:00, +03:00) Europe/Tallinn',
  'Europe/Tirane': '(UTC+01:00, +02:00) Europe/Tirane',
  'Europe/Vienna': '(UTC+01:00, +02:00) Europe/Vienna',
  'Europe/Vilnius': '(UTC+02:00, +03:00) Europe/Vilnius',
  'Europe/Warsaw': '(UTC+01:00, +02:00) Europe/Warsaw',
  'Europe/Zurich': '(UTC+01:00, +02:00) Europe/Zurich',
  'Indian/Chagos': '(UTC+06:00) Indian/Chagos',
  'Indian/Maldives': '(UTC+05:00) Indian/Maldives',
  'Indian/Mauritius': '(UTC+04:00, +05:00) Indian/Mauritius',
  'Pacific/Apia': '(UTC-11:00, -10:00, +13:00, +14:00) Pacific/Apia',
  'Pacific/Auckland': '(UTC+12:00, +13:00) Pacific/Auckland',
  'Pacific/Bougainville': '(UTC+10:00, +11:00) Pacific/Bougainville',
  'Pacific/Chatham': '(UTC+12:45, +13:45) Pacific/Chatham',
  'Pacific/Easter': '(UTC-06:00, -05:00) Pacific/Easter',
  'Pacific/Efate': '(UTC+11:00) Pacific/Efate',
  'Pacific/Fakaofo': '(UTC-11:00, +13:00) Pacific/Fakaofo',
  'Pacific/Fiji': '(UTC+12:00, +13:00) Pacific/Fiji',
  'Pacific/Galapagos': '(UTC-06:00) Pacific/Galapagos',
  'Pacific/Gambier': '(UTC-09:00) Pacific/Gambier',
  'Pacific/Guadalcanal': '(UTC+11:00) Pacific/Guadalcanal',
  'Pacific/Guam': '(UTC+10:00) Pacific/Guam',
  'Pacific/Honolulu': '(UTC-10:00) Pacific/Honolulu',
  'Pacific/Kanton': '(UTC+13:00) Pacific/Kanton',
  'Pacific/Kiritimati': '(UTC+14:00) Pacific/Kiritimati',
  'Pacific/Kosrae': '(UTC+11:00) Pacific/Kosrae',
  'Pacific/Kwajalein': '(UTC+12:00) Pacific/Kwajalein',
  'Pacific/Marquesas': '(UTC-09:30) Pacific/Marquesas',
  'Pacific/Nauru': '(UTC+12:00) Pacific/Nauru',
  'Pacific/Niue': '(UTC-11:00) Pacific/Niue',
  'Pacific/Norfolk': '(UTC+11:00, +11:30, +12) Pacific/Norfolk',
  'Pacific/Noumea': '(UTC+11:00) Pacific/Noumea',
  'Pacific/Pago_Pago': '(UTC-11:00)Pacific/Pago Pago',
  'Pacific/Palau': '(UTC+09:00)Pacific/Palau',
  'Pacific/Pitcairn': '(UTC-08:00)Pacific/Pitcairn',
  'Pacific/Port_Moresby': '(UTC+10:00)Pacific/Port Moresby',
  'Pacific/Rarotonga': '(UTC-10:00)Pacific/Rarotonga',
  'Pacific/Tahiti': '(UTC-10:00)Pacific/Tahiti',
  'Pacific/Tarawa': '(UTC+12:00)Pacific/Tarawa',
  'Pacific/Tongatapu': '(UTC+13:00, +14:00)Pacific/Tarawa',
};
export const DEFAULT_TIMEZONE = 'Europe/Bucharest';
export const DEFAULT_LOCALE = 'en-US';

export const DefaultAutonomousLockerCapabilities: AutonomousLockerCapabilities =
  {
    hasLights: false,
    hasAlarm: false,
    hasCamera: false,
    hasGsm: false,
    hasScreen: false,
  };

export const AutonomousLockerCapabilitiesLabels = [
  { name: 'hasGsm', label: 'has_gsm' },
  { name: 'hasLights', label: 'has_lights' },
  { name: 'hasAlarm', label: 'has_alarm' },
  { name: 'hasCamera', label: 'has_camera' },
  { name: 'hasScreen', label: 'has_screen' },
];

export const EXTERNAL_DOMAINS = ['nominatim.openstreetmap.org'];

export const COUNTRIES_CENTER_POINT_COORDINATES: Record<
  string,
  LatLngExpression
> = {
  ro: [45.9432, 24.9668],
  sk: [48.669, 19.699],
};

// [Minimum Latitude, Maximum Latitude, Minimum Longitude, Maximum Longitude]
export const COUNTRIES_COORDINATES_BOUNDS: Record<string, number[]> = {
  ro: [43.618682, 48.2654738, 20.2619955, 30.0454257],
  sk: [47.731389, 49.613333, 17.158889, 22.57],
};

export const COUNTRIES_MAPPING: Record<string, string> = {
  ro: 'Romania',
  sk: 'Slovakia',
};

export const RO_COUNTY_CODE = 'ro';
export const UNAUTH_MANAGEMENT_LOCALE_STOARGE_KEY = 'UNAUTH_MANAGEMENT_LOCALE';

export const CURRENT_MONTH_INTERVAL = 'current-month-interval';
export const LAST_THREE_MONTH_INTERVAL = 'last-three-month-interval';
export const LAST_SIX_MONTH_INTERVAL = 'last-six-month-interval';
export const YESTERDAY_INTERVAL = 'yesterday-interval';
export const CURRENT_WEEK_INTERVAL = 'current-week-interval';
export const LAST_WEEK_INTERVAL = 'last-week-interval';
export const LAST_MONTH_INTERVAL = 'last-month-interval';

export const APEX_CHART_LOCALES = [
  {
    name: 'en',
    options: {
      months: [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
      ],
      shortMonths: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      days: [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ],
      shortDays: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
      toolbar: {
        exportToSVG: 'Download SVG',
        exportToPNG: 'Download PNG',
        menu: 'Menu',
        selection: 'Selection',
        selectionZoom: 'Selection Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Reset Zoom',
      },
    },
  },
  {
    name: 'ro',
    options: {
      months: [
        'Ianuarie',
        'Februarie',
        'Martie',
        'Aprilie',
        'Mai',
        'Iunie',
        'Iulie',
        'August',
        'Septembrie',
        'Octobrie',
        'Noiembrie',
        'Decembrie',
      ],
      shortMonths: [
        'Ian',
        'Feb',
        'Mar',
        'Apr',
        'Mai',
        'Iun',
        'Iul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ],
      days: [
        'Duminica',
        'Luni',
        'Marti',
        'Miercuri',
        'Joi',
        'Vineri',
        'Sambata',
      ],
      shortDays: ['D', 'L', 'M', 'Mie', 'J', 'V', 'S'],
      toolbar: {
        exportToSVG: 'Descarcă SVG',
        exportToPNG: 'Descarcă PNG',
        menu: 'Meniu',
        selection: 'Selectare',
        selectionZoom: 'Selectare Zoom',
        zoomIn: 'Zoom In',
        zoomOut: 'Zoom Out',
        pan: 'Panning',
        reset: 'Resetează Zoom',
      },
    },
  },
];

export const MAP_LOCALE_TO_APEX_CHART_LOCALE: Record<string, string> = {
  'ro-RO': 'ro',
  'en-US': 'en',
};

export const MANAGEMENT_START_DATE_STORAGE_KEY = 'MANAGEMENT_START_DATE';
export const CONTROL_UNIT_LC2ML = 'control-unit-LC2ML';
export const CONTROL_UNIT_LC3SML = 'control-unit-LC3SML';
export const DEFAULT_LOCKER_CENTRAL_UNIT_TYPE = 'LC3SML';
export const DRAWER_SIZE_TO_EXCLUDE = [CONTROL_UNIT_LC2ML, CONTROL_UNIT_LC3SML];

export const CONTROL_UNIT_STRUCTURE_TO_CONTROL_UNIT_INFO: {
  [key: string]: { class: string; position: number };
} = {
  'L1,M2,L1': { class: 'control-unit-LC2ML', position: 1 },
  'L1,S3,M1,L1': { class: 'control-unit-LC3SML', position: 1 },
};

export const userListDataGridLegend = [
  { label: 'active_user', bgColorClass: 'user-active' },
  { label: 'deleted_user', bgColorClass: 'user-deleted' },
];

export const DEFAULT_AUTONOMOUS_LOCKER_TEMPLETE = 'AUT1';

export const AUTONOMOUS_LOCKER_TEMPLATES: LockerTemplate[] = [
  {
    type: 'AUT1',
    label: 'arka_autonomous_1',
    columns: [
      {
        id: 'C1',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 8 },
          { size: 2, quantity: 2 },
        ],
      },
      {
        id: 'C2',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 8 },
          { size: 2, quantity: 2 },
        ],
      },
    ],
  },
  {
    type: 'AUT2',
    label: 'arka_autonomous_2',
    columns: [
      {
        id: 'C1',
        structure: [
          { size: 8, quantity: 1 },
          { size: 6, quantity: 2 },
          { size: 4, quantity: 4 },
          { size: 8, quantity: 2 },
        ],
      },
      {
        id: 'C2',
        structure: [
          { size: 8, quantity: 1 },
          { size: 6, quantity: 2 },
          { size: 4, quantity: 3, isCentralUnit: true },
          { size: 4, quantity: 1 },
          { size: 8, quantity: 2 },
        ],
      },
      {
        id: 'C3',
        structure: [
          { size: 9, quantity: 2 },
          { size: 7, quantity: 1 },
          { size: 5, quantity: 3 },
          { size: 10, quantity: 1 },
        ],
      },
    ],
  },
];

export const CONNECTED_LOCKER_TEMPLATES: LockerTemplate[] = [
  {
    type: 'LC3SML',
    label: 'arka_type_1',
    columns: [
      {
        id: 'C1',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 8 },
          { size: 2, quantity: 1 },
          { size: 3, quantity: 1 },
        ],
      },
      {
        id: 'C2',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 5, isCentralUnit: true },
          { size: 1, quantity: 3 },
          { size: 2, quantity: 1 },
          { size: 3, quantity: 1 },
        ],
      },
      {
        id: 'C3',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 8 },
          { size: 2, quantity: 1 },
          { size: 3, quantity: 1 },
        ],
      },
    ],
  },
  {
    type: 'LC2ML',
    label: 'arka_type_2',
    columns: [
      {
        id: 'C1',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 8 },
          { size: 2, quantity: 3 },
        ],
      },
      {
        id: 'C2',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 6, isCentralUnit: true },
          { size: 2, quantity: 2 },
          { size: 3, quantity: 1 },
        ],
      },
      {
        id: 'C3',
        structure: [
          { size: 3, quantity: 1 },
          { size: 1, quantity: 8 },
          { size: 2, quantity: 3 },
        ],
      },
    ],
  },
];

// centralUnitStructure: ex: 15 -> first numuber represents the drawer type
// second number represents the number of drawers
// a centralUnitStructure with a value of 15 has 5 drawers of type 1
export const LOCKER_MODELS = [
  { type: 'LC3SML', centralUnitStructure: '15', drawerTypes: [1, 2, 3] },
  { type: 'LC2ML', centralUnitStructure: '16', drawerTypes: [1, 2, 3] },
];

export const drawerSizeToClassNames: Record<number, string> = {
  1: 'small-drawer-connected',
  2: 'medium-drawer-connected',
  3: 'large-drawer-connected',
  4: 'small-half-drawer-autonomous',
  5: 'small-full-drawer-autonomous',
  6: 'medium-half-drawer-autonomous',
  7: 'medium-full-drawer-autonomous',
  8: 'large-half-drawer-autonomous',
  9: 'large-full-drawer-autonomous',
  10: 'extra-large-full-drawer-autonomous',
};

export const AUTONOMOUS_LOCKER_TYPE_I = 'AUT1';
export const AUTONOMOUS_LOCKER_TYPE_II = 'AUT2';

export const AUTONOMOUS_LOCKER_COLUMN_TEMPLATES: ColumnTemplate[] = [
  {
    type: 'A',
    structure: [
      { size: 3, quantity: 1 },
      { size: 1, quantity: 8 },
      { size: 2, quantity: 2 },
    ],
  },
  {
    type: 'B',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 6 },
    ],
  },
  {
    type: 'C',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 1 },
      { size: 1, quantity: 6 },
      { size: 2, quantity: 2 },
    ],
  },
  {
    type: 'D',
    structure: [
      { size: 3, quantity: 1 },
      { size: 1, quantity: 10 },
      { size: 2, quantity: 1 },
    ],
  },
  {
    type: 'E',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 2 },
      { size: 1, quantity: 2 },
      { size: 2, quantity: 3 },
    ],
  },
  {
    type: 'F',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 1 },
      { size: 1, quantity: 4 },
      { size: 2, quantity: 3 },
    ],
  },
  {
    type: 'G',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 2 },
      { size: 1, quantity: 6 },
      { size: 2, quantity: 1 },
    ],
  },
];

// '31,18,22' first number is the drawer type and the second is the quantity
// '31,18,22'  translates to 1 drawer of size 3 8 drawrs of size 1 and 2 drawers of size 2
export const AUTONOMOUS_DRAWER_STRUCTURE_TO_COLUMN_TYPE: {
  [key: string]: string;
} = {
  '31,18,22': 'A',
  '31,26': 'B',
  '31,21,16,22': 'C',
  '31,110,21': 'D',
  '31,22,12,23': 'E',
  '31,21,14,23': 'F',
  '31,22,16,21': 'G',
};

export const CONNECTED_LOCKER_COLUMN_TEMPLATES: ColumnTemplate[] = [
  {
    type: 'A',
    structure: [
      { size: 3, quantity: 1 },
      { size: 1, quantity: 8 },
      { size: 2, quantity: 1 },
      { size: 3, quantity: 1 },
    ],
  },
  {
    type: 'B',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 5 },
      { size: 3, quantity: 1 },
    ],
  },
  {
    type: 'C',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 1 },
      { size: 1, quantity: 6 },
      { size: 2, quantity: 1 },
      { size: 3, quantity: 1 },
    ],
  },
  {
    type: 'D',
    structure: [
      { size: 3, quantity: 1 },
      { size: 1, quantity: 10 },
      { size: 3, quantity: 1 },
    ],
  },
  {
    type: 'E',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 2 },
      { size: 1, quantity: 2 },
      { size: 2, quantity: 2 },
      { size: 3, quantity: 1 },
    ],
  },
  {
    type: 'F',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 1 },
      { size: 1, quantity: 4 },
      { size: 2, quantity: 2 },
      { size: 3, quantity: 1 },
    ],
  },
  {
    type: 'G',
    structure: [
      { size: 3, quantity: 1 },
      { size: 2, quantity: 1 },
      { size: 1, quantity: 6 },
      { size: 2, quantity: 3 },
    ],
  },
  {
    type: 'H',
    structure: [
      { size: 3, quantity: 1 },
      { size: 1, quantity: 8 },
      { size: 2, quantity: 3 },
    ],
  },
];

export const CONNECTED_DRAWER_HEIGHTS = {
  '1': 35,
  '2': 70,
  '3': 140,
} as const;

export const AUTONOMOUS_LOCKER_TYPE = 2;

export const LOW_BATTERY_LEVEL = '75%';

export const LOW_BATTERY_LOCKERS_KEYS = [
  'lockerCode',
  'batteryPercentage',
  'createdAt',
  'updatedAt',
  'lastSyncDate',
  'name',
  'onlineStatus',
  'lockerType',
];

export const LOW_BATTERY_LOCKERS_HEADERS = [
  'locker_code',
  'battery_level',
  'created_at',
  'updated_at',
  'synced_at',
  'name',
  'conectivity',
  'locker_type',
];

export const activitiesListDataGridLegend = [
  { label: 'active_activity', bgColorClass: 'user-active' },
  { label: 'deleted_activity', bgColorClass: 'user-deleted' },
];

export const DB_DRAWER_COLUMN_ID_PREFIX = 'db-drawer-column';
